export const WORDS = [
  'angmoh',
  'hamsum',
  'sayang',
  'alamak',
  'anyhow',
  'mugger',
  'songbo',
  'goblok',
  'hilang',
  'lippit',
  'obiang',
  'jiseow',
  'kachng',
  'sambal',
  'baotoh',
  'ordloh',
  'tokkok',
  'horlan', 
  'meepok', 
  'sotong',
  'friend',
  'steady',   
  'lohsoh',
  'wahlan',
  'gostun',
  'wahkao',
  'twakee',
  'kiotio',
  'orkwee',
  'orhorh',
  'hawker',
  'choboh',
  'laohor',
  'lumpah',
  'haemee',
  'makcik',
  'kesian',
  'ahlong',
  'paikia',
  'goondu',
  'garang',
  'barang',
  'errerr',
  'pasang',
  'kacang',
  'towkay',
  'mahmee',
  'lebber',
  'kisiow',
  'kekkee',
  'kaypoh',
  'owadio',
  'tiaohe',
  'meekia',
  'auntie',
  'boheng',
  'seoweh',
  'diedie',
  'zhicha',
  'wayang',
  'sheshe',
  'zaohor',
  'humsup',
  'chikak',
  'lemang',
  'daikor',
  'oreddy',
  'orkong',
  'akasai',
  'tanlui',  
  'senang', 
  'durian',  
  'cheeko',
  'mahjie',
  'kelong',
  'lorsor',
  'kumsia',
  'laylio',
  'pandai',
  'borrow',
  'lembek',
  'batang',
  'bochap',
  'karpak',
  'rempah',
  'hammer',
  'laoyah',
  'limpei',
  'terbak',
  'tolong',
  'yandao',
  'angkat',
  'wandan',
  'lizard',
  'laopok',
  'tripod',
  'siamah',
  'fierce',
  'lakopi',
  'pakkiu',
  'chiobu',
  'pisang',
  'basket',
  'aiyoyo',
  'bopian',
  'capteh',
  'chifan',
  'gahmen',
  'mampat',
  'holiao',
  'ahseng',
  'pomata',
  'tuatao',
  'tehgao',
  'follow',
  'hupply',
  'peepee',
  'mampus',
  'paoniu',
  'gostan',
  'kongsi',
  'pakcik',
  'banana',
  'meesua',  
  'ahbeng',
  'sorzai',
  'loklok',
  'kosong',
  'zaokng',
  'bopeng',
  'bohgay',
  'gehgeh',
  'jialat',
  'ahkong',
  'lantui',
  'laling',
  'potong',
  'choobi',
  'pakzam',
  'suakoo',
  'horfun',
  'bohlui',
  'goreng',
  'chiong',
  'siowon',
  'kuakua',
  'siliao',
  'sampat',
  'revert',
  'hentam',
  'meimei',
  'hantze',
  'kiaboh',
  'paiseh',
  'bohjio',
  'ahlian',
  'siaoon',
  'senget',
  'faster',
  'laosai',
  'lobang',
  'lormee',
  'kuahee',
  'dunnid',
  'lembik',
  'obasan',
  'prolly',
  'sarkar',
  'hantam',
  'guanxi',
  'korkor',
  'minyak',
  'paktor',
  'towgay',
  'action',
  'bohlat',
  'hengah',
  'takkiu',
  'haebee',
  'tumsim',
  'tuppai',
  'bising',
  'kuppoh',
  'zhunbo',
  'kumlan',
  'ahchek',
  'seehow',
  'liddat',
  'cheapo',
  'bochup',
  'cowpeh',  
  'tinteh',
  'kakpui',
  'maihum',
  'bohsay',
  'wuliao', 
  'dunshy',
  'laolan',
  'ahhuay',
  'laonua',
  'nehneh',
  'abuden',
  'tarpor',
  'gerger',
  'huatah',
  'bakkwa',
  'angpow', 
  'popiah',
  'kuachi',
  'orhhor',
  'paitao',
  'taisoi',
  'monyet',
  'xialan',
  'kichia',
  'pengat',
  'kuansi',
  'tution',
  'wahsay',
  'siowah',
  'tuakee',
  'sekali',
  'wahlah',
  'laogoh',
  'siudai',
  'boyboy',
  'hiaomi',
  'terkor',
  'sargen',
  'hormat',
  'paikua',
  'selang',
  'amacam',
  'bolang',
  'limpeh',
  'gaigai',
  'pompom',
  'kiameh',
  'bohhew',
  'pariah',
  'seowon',
  'mummum',
  'wahseh',
  'paimia',
  'keksai',
  'unglam',
  'sumpah',
  'wahlau',
  'humchi',
  'ahgirl',
  'jisiao',
  'bohgeh',
  'bakero',
  'keksim',
  'laopeh',
  'tuakow',
  'pokkai',
  'sinseh',
  'lumber',
  'dubdew',
  'lelong',
  'jamban',
  'ninabu',
  'weewee',
  'dieded',
  'sialan',
  'jibaho',
  'siosio',
  'thosai',
  'aircon',
  'taoyan',
  'bombom',
  'cantik',
  'taitai',
]