export const WORD_DEFINITIONS = new Map([
  ['JIBAHO', 'Order me 100plus, JIBAHO plz! '],
  ['KICHIA', 'My exams KICHIA liao, jialat!'],
  ['KIOTIO', 'Today struck 4D, KIOTIO!'],
  ['KUANSI', 'Tuas this kind of ulu place, I not KUANSI'],
  ['KUPPOH', 'One bottle of KUPPOH, Kickapoo plz'],
  ['LAKOPI', 'Let\'s go kopitiam and LA KOPI'],
  ['LAOHOR', 'One TIGER BEER, Lao Hor plz!'],
  ['LALING', 'Ohhh, my lurf, my LALING'],
  ['LAYLIO', 'Eh, on the laylio, plz. I want to listen to class 95'],
  ['LEBBER', 'You studying A LEBBER or O LEBBER one?'],
  ['LIPPIT', 'Can LIPPIT plz? Say again?'],
  ['ORDLOH', 'ORH Loh!'],
  ['ORHHOR', 'ORH-HOR! I\'m going to tell mummy!'],
  ['ORHORH', 'Sleeping ah? ORH-ORH oreddy?'],
  ['SAYANG', 'Dun be sad, I SAYANG you'],
  ['SIAOON', 'He so siao on, exams so far away oreddy start studying'],
  ['TINTEH', 'Aiyoh, so messy ah! TINTEH!'],
  ['HAMSUM', 'Happy birthday, Tobi! Dun worry, you still look young and HAMSUM!'],
])
